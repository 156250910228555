import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5/react';

// 1. Get projectId
const projectId = 'a625e2fd20f5332ab80f31fc4904b51c'

// 2. Set chains
const mainnet = {
    chainId: 56,
    name: 'Binance Smart Chain',
    currency: 'BNB',
    explorerUrl: 'https://bscscan.com',
    rpcUrl: 'https://rpc.ankr.com/bsc'
}

// 3. Create a metadata object
const metadata = {
    name: 'Country Coins',
    description: 'Country Coins',
    url: 'https://countrycoins.world/', // origin must match your domain & subdomain
    icons: ['https://countrycoins.world/assets/img/']
}

// 4. Create Ethers config
const ethersConfig = defaultConfig({
    /*Required*/
    metadata,

    /*Optional*/
    enableEIP6963: true, // true by default
    enableInjected: true, // true by default
    enableCoinbase: true, // true by default
    rpcUrl: '...', // used for the Coinbase SDK
    defaultChainId: 1, // used for the Coinbase SDK
})

// 5. Create a Web3Modal instance
createWeb3Modal({
    ethersConfig,
    chains: [mainnet],
    projectId,
    enableAnalytics: true // Optional - defaults to your Cloud configuration
})

export default function ConnectButton() {
    return <w3m-button />
  }